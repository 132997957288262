import React from 'react'

const IndexPage = () => {
    
  return (
   <div>
       <h1>Index page</h1>
   </div>
  );


}

export default IndexPage
